import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const NavBarLink = ({ exact, path, title }) => (
  <li className="nav-item">
    <NavLink className="nav-link" exact={exact} to={path}>
      {title}
    </NavLink>
  </li>
)

NavBarLink.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
NavBarLink.defaultProps = {
  exact: false
}

export default NavBarLink
