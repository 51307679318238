import React, { useState } from 'react'

import { pickBy } from 'lodash-es'

import DateTime from '@therms/web-common/components/Inputs/DateTime'
import Loading from '@therms/web-common/components/Loading'
import SelectAccessLevel from '../components/SelectAccessLevel'
import SelectOrganizations from '../components/SelectOrganizations'
import Text from '@therms/web-common/components/Inputs/Text'

import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { EMAILS_API_SEND } from '../shared/routePaths'

import adminApi from 'services/adminApi'

import './index.scss'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const Emails = () => {
  const [organizationIds, setOrganizationIds] = useState([])
  const [selectedAccess, setSelectedAccess] = useState(0)
  const [scheduleDate, setScheduleDate] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [sendingEmails, setSendingEmails] = useState(false)
  const [emailsSent, setEmailsSent] = useState(false)

  const getFormErrors = () => {
    const errors = []

    const messageLength = editorState.getCurrentContent().getPlainText().length
    if (messageLength < 140) errors.push('Message body should be at least 140 characters')

    const subjectLength = document.querySelector('input[name=subject]').value.length
    if (!subjectLength) errors.push('Subject field is required')

    console.log(errors)

    if (errors.length) return errors

    return false
  }

  const formReset = () => {
    document.getElementById('new-mail')
    setEditorState(EditorState.createEmpty())
    setScheduleDate(new Date().toISOString())
    setEmailsSent(true)
  }

  const handleNewMailSubmit = async event => {
    event.preventDefault()

    if (Boolean(getFormErrors())) {
      alert(`Please fix this problem(s): ${getFormErrors().map(error => `${error  } `)}`)
      return
    }

    if (
      !organizationIds.length &&
      !window.confirm(`Do you want to send the communication to every organization?`)
    )
      return

    const submitButton = document.querySelector('input[type=submit]')

    submitButton.disabled = true

    const emailPayload = pickBy({
      body: stateToHTML(editorState.getCurrentContent()),
      fromEmail: document.querySelector('input[name=from-email]').value,
      fromName: document.querySelector('input[name=from-name]').value,
      minUserAccessLevel: selectedAccess,
      organizationIds: organizationIds.length ? organizationIds : null,
      replyToEmail: document.querySelector('input[name=reply-to-email]').value,
      sendDateTime: scheduleDate,
      subject: document.querySelector('input[name=subject]').value,
    })

    try {
      setSendingEmails(true)
      await adminApi.post(EMAILS_API_SEND, emailPayload)

      formReset()
    } catch (err) {
      alert('Email sending failed, you can check the console for details')

      console.log(err.response)
    } finally {
      setSendingEmails(false)
      submitButton.disabled = true
    }
  }

  if (sendingEmails) return <Loading text="Sending Emails" />

  if (emailsSent)
    return (
      <div className="d-flex flex-col items-center justify-content-center">
        <h2>Emails Successfully Sent</h2>

        <button className="btn btn-lg btn-primary" onClick={() => setEmailsSent(false)}>
          SEND MORE
        </button>
      </div>
    )

  return (
    <div className="container">
      <div className="mb-4 text-center">
        <h1 className="mb-0">Send a New Email</h1>

        <small className="font-weight-bold text-muted">Fields marked with * are mandatory</small>
      </div>

      <form id="new-mail" onSubmit={handleNewMailSubmit}>
        <div className="mb-3">
          <h4 className="text-muted mb-2">Organizations</h4>

          <SelectOrganizations
            organizationIds={organizationIds}
            setOrganizationIds={setOrganizationIds}
          />
        </div>

        <div className="row">
          <div className="col-12 col-sm-6 mb-3">
            <h4 className="text-muted mb-2">Send Time</h4>

            <DateTime name="schedule-time" onChange={setScheduleDate} value={scheduleDate} />
          </div>

          <div className="col-12 col-sm-6 mb-3">
            <h4 className="text-muted mb-2">Minimum Access Level</h4>

            <SelectAccessLevel
              selectedAccess={selectedAccess}
              setSelectedAccess={setSelectedAccess}
            />
          </div>
        </div>

        <div className="mb-3">
          <h4 className="text-muted mb-2">Email Header</h4>

          <div className="row">
            <div className="col-12 col-md">
              <Text label="Sender Name" name="from-name" placeholder="THERMS Team" />
            </div>

            <div className="col-12 col-md">
              <Text
                label="Sender Email"
                name="from-email"
                placeholder="support@therms.io"
                type="email"
              />
            </div>

            <div className="col-12 col-md">
              <Text
                label="Reply to Email"
                name="reply-to-email"
                placeholder="support@therms.io"
                type="email"
              />
            </div>
          </div>

          <Text label="Subject *" name="subject" placeholder="Good News Everyone..." />
        </div>

        <div className="mb-3">
          <h4 className="text-muted mb-0">Message *</h4>
          <small className="text-muted mb-2">140 characters minimum</small>

          <Editor
            editorState={editorState}
            toolbarClassName="editor-toolbar"
            wrapperClassName="editor-wrapper"
            editorClassName="editor"
            onEditorStateChange={setEditorState}
            placeholder="Write your message here..."
          />
        </div>

        <div className="d-flex justify-content-end">
          <input type="submit" className="btn btn-lg btn-primary" value="SEND" />
        </div>
      </form>
    </div>
  )
}

Emails.propTypes = {}
Emails.defaultProps = {}

export default Emails
