import React from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

import DateTime from '@therms/web-common/components/DateTime'
import Loading from '@therms/web-common/components/Loading'
import FailedToLoadResource from '../../../components/FailedToLoadResource'

import { makeSingleRegistrationRoute, REGISTRATIONS_API_ROOT } from '../shared/routePaths'

const Registrations = () => {
  const { data: pendingRegistrations, error } = useSWR(REGISTRATIONS_API_ROOT)

  if (error) return <FailedToLoadResource error={error} />
  if (!pendingRegistrations) return <Loading />

  return (
    <div className="container">
      <h1 className="text-center mb-4">Pending Registrations</h1>

      <ul className="list-group">
        {pendingRegistrations.map(reg => (
          <li key={reg.id}>
            <Link
              to={makeSingleRegistrationRoute(reg.id)}
              className="d-flex justify-content-between list-group-item mb-2 text-decoration-none"
            >
              <div>
                <strong className="font-weight-bold">{reg.organization.name}</strong>
              </div>

              <span className="text-muted">
                <DateTime relativeTime timestamp={reg.createdAt} />
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

Registrations.propTypes = {}
Registrations.defaultProps = {}

export default Registrations
