import { useEffect, useState } from 'react'
import adminApi from '../../../../services/adminApi'
import axios from 'axios'

const useReferralOrganizationName = (referralCode) => {
  const [referralName, setReferralName] = useState('')

  useEffect(() => {
    if (referralCode) {
      const cancelTokenSource = axios.CancelToken.source()
      adminApi(`/organizations/organization/${referralCode}`, { cancelToken: cancelTokenSource.token }).then(response => {
        const { name: organizationName } = response.data
        setReferralName(organizationName)
      }).catch(error => {
        if (!axios.isCancel(error)) {
          console.log('useReferralOrganizationName(): ', error)
        }
      })

      return () => cancelTokenSource.cancel()
    }

    return undefined
  }, [referralCode])

  return referralName
}

export default useReferralOrganizationName