import { createAction, handleActions } from 'redux-actions'
// import { createSelector } from 'reselect'
import adminApi from '../../services/adminApi'

const initialState = {
  isAuthenticated: false,
}

// selectors
export const isAuthenticated = state => state.auth.isAuthenticated

// actions
const setIsAuthenticated = createAction('setIsAuthenticated')

export const fetchAndSetAuth = () => async dispatch => {
  // eslint-disable-next-line no-useless-catch
  try {
    await adminApi.post('/authenticate')
    dispatch(setIsAuthenticated(true))
  } catch (e) {
   console.log('e', e)
  }
}

export default handleActions(
  {
    [setIsAuthenticated]: (state, action) => ({
      ...state,
      isAuthenticated: action.payload,
    }),
  },
  initialState,
)
