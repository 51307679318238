import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'

import FailedToLoadResource from 'components/FailedToLoadResource'
import Loading from '@therms/web-common/components/Loading'
import Select from '@therms/web-common/components/Inputs/Select'

import { ORGANIZATIONS_API_ALL } from 'modules/Organizations/shared/routePaths'

const SelectOrganizations = ({ organizationIds, setOrganizationIds }) => {
  const { data: organizations, error } = useSWR(ORGANIZATIONS_API_ALL)

  const organizationOptions = useMemo(() => {
    if (!organizations) return {}

    return organizations.map(({ id, name }) => {
      return { label: name, value: id }
    })
  }, [organizations])

  if (error) return <FailedToLoadResource error={error} />

  if (!organizations) return <Loading />

  return (
    <Select
      multi
      name="select-organizations"
      onChange={({ target }) => setOrganizationIds(target.value)}
      options={organizationOptions}
      placeholder="Add Some Organizations"
      subText="Leaving this field blank will send the communication to every organization"
      value={organizationIds}
    />
  )
}

SelectOrganizations.propTypes = {
  organizationIds: PropTypes.array.isRequired,
  setOrganizationIds: PropTypes.func.isRequired,
}

export default SelectOrganizations
