import React from 'react'
import Card from '@therms/web-common/components/Card'
import useSWR from 'swr'

const ReportsCard = () => {
  const { data } = useSWR('/reports/stats-counts')

  const { lastWeek, thisWeek, today, yesterday } = data || {}

  return (
    <Card heading="Reports">
      <div className="flex-row-jcb">
        Today/Yesterday
        <div>
          {today || 0} / {yesterday || 0}
        </div>
      </div>

      <div className="flex-row-jcb">
        This Week/Last Week
        <div>
          {thisWeek || 0} / {lastWeek || 0}
        </div>
      </div>
    </Card>
  )
}

ReportsCard.propTypes = {}
ReportsCard.defaultProps = {}

export default ReportsCard
