import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

const FailedToLoadResource = ({ error }) => {
  useEffect(() => {
    console.log('FailedToLoadResource error', error)
  }, [error])
  
  return (
    <div className="text-danger">Failed to load</div>
  )
}

FailedToLoadResource.propTypes = {
  error: PropTypes.string,
}
FailedToLoadResource.defaultProps = {
  error: ''
}

export default FailedToLoadResource
