export const REGISTRATIONS_ROOT = '/registrations'
export const REGISTRATIONS_SINGLE = '/registrations/:registrationId'

export const REGISTRATIONS_API_ROOT = '/organizations/pending-registration'
export const REGISTRATIONS_API_SINGLE =
  '/organizations/pending-registration?organizationRegistrationId=:registrationId'

export const REGISTRATIONS_API_CREATE = '/organizations/register-organization'
export const REGISTRATIONS_API_DELETE =
  '/organizations/delete-pending-registration?organizationRegistrationId=:registrationId'
export const REGISTRATIONS_API_COMMENT_CREATE = '/organizations/comment-organization-registration'

export const makeSingleRegistrationRoute = registrationId => {
  return REGISTRATIONS_SINGLE.replace(':registrationId', registrationId)
}

export const makeSingleRegistrationRequest = registrationId => {
  return REGISTRATIONS_API_SINGLE.replace(':registrationId', registrationId)
}

export const makeDeleteRegistrationRoute = registrationId => {
  return REGISTRATIONS_API_DELETE.replace(':registrationId', registrationId)
}
