import React from 'react'
// import PropTypes from 'prop-types'
import Card from '@therms/web-common/components/Card'
import useSWR from 'swr'

const OrganizationsCard = () => {
  const { data: activeOrganizations } = useSWR(`/organizations/active-count`)
  const { data: delinquentOrganizations } = useSWR(`/organizations/delinquent-count`)
  const { data: trialOrganizations } = useSWR(`/organizations/trial-count`)

  return (
    <Card heading="Organizations">
      <div className="flex-row-jcb">
        Subscriptions
        <div>
          <span className="badge badge-info">{activeOrganizations}</span>
        </div>
      </div>

      {trialOrganizations > 0 && (
        <div className="flex-row-jcb">
          Active Trial
          <div>
            <span className="badge badge-primary">{trialOrganizations}</span>
          </div>
        </div>
      )}

      {delinquentOrganizations > 0 && (
        <div className="flex-row-jcb">
          Delinquent
          <div>
            <span className="badge badge-warning">{delinquentOrganizations}</span>
          </div>
        </div>
      )}
    </Card>
  )
}

OrganizationsCard.propTypes = {}
OrganizationsCard.defaultProps = {}

export default OrganizationsCard
