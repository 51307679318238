import React from 'react'
import useSWR from 'swr'
import Loading from '@therms/web-common/components/Loading'
import FailedToLoadResource from '../../../components/FailedToLoadResource'

import OrganizationListItem from '../components/OrganizationListItem'

const TrialOrganizations = () => {
  const { data, error, isValidating } = useSWR(`/organizations/trial-organizations`)

  if (error) return <FailedToLoadResource error={error} />

  if (!data) return <Loading />

  return (
    <div>
      {isValidating && (
        <div className={data?.length ? 'position-absolute' : ''}>
          <Loading />
        </div>
      )}

      <div className="mt-3">
        {data.map(org => (
          <OrganizationListItem
            key={org.id}
            organization={org}
          />
        ))}
      </div>

      {data.length < 1 && (
        <div className="my-5 text-center text-muted">
          <i>No Trial Organizations</i>
        </div>
      )}
    </div>
  )
}

TrialOrganizations.propTypes = {}
TrialOrganizations.defaultProps = {}

export default TrialOrganizations
