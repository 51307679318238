import React, {  useMemo } from 'react'
import PropTypes from 'prop-types'
import calculatePlanPrice from "../utils/calculatePlanPrice"

const ActiveUsersPaidPlanAmount = ({ userCount }) => {
  const price = useMemo(() => {
    // eslint-disable-next-line default-case
    return calculatePlanPrice(userCount)
  }, [userCount])

  return <span>{price}</span>
}

ActiveUsersPaidPlanAmount.propTypes = {
  userCount: PropTypes.number.isRequired,
}
ActiveUsersPaidPlanAmount.defaultProps = {}

export default ActiveUsersPaidPlanAmount
