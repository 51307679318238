import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr/esm/use-swr'
import Loading from '@therms/web-common/components/Loading'
import OrganizationReferralRegistrationListItem from './OrganizationReferralRegistrationListItem'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const OrganizationReferrals = ({ referralCode }) => {
  const [areClicksExpanded, setAreClicksExpanded] = useState(false)
  const { data: referralData, error, validating } = useSWR(
    `referral/info?referralCode=${referralCode}`,
  )
  
  if (!referralCode) return null

  if (error?.response.status === 404)
    return (
      <div className="text-center text-muted font-lg p-3">Organization has no referrals yet</div>
    )

  if (validating && !referralData) return <Loading centered />

  const stats = referralData?.stats || {}
  const {
    clicks,
    clicksByReferralURL,
    payoutsEarnedTotal,
    pendingPayoutTotal,
    payoutsEarned,
    pendingPayouts,
    registered,
  } = stats

  return (
    <>
      <div className="d-flex flex-wrap">
        <div className="flex-fill m-2">
          <h5>Credits</h5>

          <div className="d-flex mt-1">
            <div className="mr-4">
              <div className="text-success font-weight-bold">Earned</div>
              <div className="big-text">${payoutsEarnedTotal || 0}</div>
            </div>

            <div>
              <div className="text-muted font-weight-bold">Pending</div>
              <div className="big-text">${pendingPayoutTotal || 0}</div>
            </div>
          </div>
        </div>

        <div className="flex-fill m-2">
          <h5>Referred Accounts</h5>

          <div className="d-flex mt-1">
            <div className="mr-4">
              <div className="text-muted font-weight-bold">Confirmed</div>
              <div className="big-text text-secondary">{payoutsEarned || 0}</div>
            </div>

            <div className="mr-4">
              <div className="text-muted font-weight-bold">Pending</div>
              <div className="big-text text-muted">{pendingPayouts || 0}</div>
            </div>

            <div className="mr-4">
              <div className="text-muted font-weight-bold">Total</div>
              <div className="big-text">{registered || 0}</div>
            </div>
          </div>
        </div>

        <div className="flex-fill m-2">
          <div className="d-flex">
            <h5 className="mr-2">Link Clicks</h5>

            {!!clicksByReferralURL && (
              <button
                className="btn btn-outline-secondary btn-sm btn-short mb-2"
                onClick={() => setAreClicksExpanded(!areClicksExpanded)}
                type="button"
              >
                <FAIcon name={areClicksExpanded ? 'compress-alt' : 'expand-alt'} />
              </button>
            )}
          </div>

          <div className="d-flex align-items-end">
            <div className="mr-4">
              <div className="text-muted font-weight-bold">Total</div>
              <div className="big-text">{clicks}</div>
            </div>
          </div>
        </div>
      </div>

      {!!clicksByReferralURL && areClicksExpanded && (
        <div>
          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Site</th>
                  <th>Clicks</th>
                </tr>
              </thead>

              <tbody>
                {Object.entries(clicksByReferralURL).map(([url, urlClicks]) => (
                  <tr key={url}>
                    <td>{url}</td>
                    <td>{urlClicks}</td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <th>Total</th>
                  <td>{clicks}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}

      {!!referralData?.registrations.length && (
        <>
          <h5 className="mt-4">Referred Organizations</h5>

          {referralData.registrations.map(registration => (
            <div className="mt-3" key={registration._id}>
              <OrganizationReferralRegistrationListItem registrationRecord={registration} />
            </div>
          ))}
        </>
      )}
    </>
  )
}

OrganizationReferrals.propTypes = {
  referralCode: PropTypes.string.isRequired,
}

export default OrganizationReferrals
