import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Dashboard from './Dashboard/Routes'
import Emails from './Emails/Routes'
import Organizations from './Organizations/Routes'
import Registrations from './Registrations/Routes'

import { EMAILS_ROOT } from './Emails/shared/routePaths'
import { REGISTRATIONS_ROOT } from './Registrations/shared/routePaths'
import { ORGANIZATIONS_ROOT } from './Organizations/shared/routePaths'

export default () => (
  <Switch>
    <Route component={Emails} path={EMAILS_ROOT} />
    <Route component={Registrations} path={REGISTRATIONS_ROOT} />
    <Route component={Organizations} path={ORGANIZATIONS_ROOT} />
    <Route component={Dashboard} path="/" />
  </Switch>
)
