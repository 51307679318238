import React, { useEffect, useMemo } from 'react'
// import PropTypes from 'prop-types'
import useSWR from 'swr'
import qs from 'qs'
import Loading from '@therms/web-common/components/Loading'
import BarChart from 'react-easy-bar-chart'
import calculatePlanPrice from '../utils/calculatePlanPrice'

const OrganizationsSubscriptionTierChart = () => {
  const { data, isValidating } = useSWR(
    `/organizations/organizations?${qs.stringify({
      query: { where: { active: true } },
    })}`,
  )

  useEffect(() => {}, [])

  const chartData = useMemo(() => {
    if (!data || !data.length) return false

    const d = data.reduce((acc, value) => {
      const tier = `$ ${calculatePlanPrice(value.activeUsers)}`

      if (!acc[tier]) acc[tier] = 0

      acc[tier]++

      return acc
    }, {})

    return Object.entries(d).map(([tier, count]) => ({ title: tier, value: count }))
  }, [data])

  if (isValidating || !chartData) return <Loading />

  return (
    <div className="mp-3">
      <BarChart xAxis="" yAxis="" height={200} width={window?.innerWidth - 100} data={chartData} />
    </div>
  )
}

OrganizationsSubscriptionTierChart.propTypes = {}
OrganizationsSubscriptionTierChart.defaultProps = {}

export default OrganizationsSubscriptionTierChart
