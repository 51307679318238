import React from 'react'
import PropTypes from 'prop-types'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const OrganizationUsersList = ({ users }) => {
  return (
    <div>
      {users.map(user => (
        <div className="card p-1 mb-1" key={user.id}>
          <div>
            {user.firstName} {user.lastName}
          </div>

          <div>
            {user?.userMetadata && (
              <span className="font-weight-light mr-1">
                {user?.userMetadata?.admin ? (
                  <span className="text-warning">
                    <FAIcon name="user-shield" /> Administrator
                  </span>
                ) : (
                  <span>Access Level: {user?.userMetadata?.accessLevel}</span>
                )}
              </span>
            )}
            {user.title}
          </div>

          {user?.userAccount && (
            <div>
              <a
                href={`mailto:${user?.userAccount?.email}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {user.userAccount?.email}
              </a>{' '}
              {user?.userAccount?.password}{' '}
              <button
                className="btn btn-primary btn-sm btn-short"
                onClick={() => {
                  window.open(
                    `https://${
                      process.env.NODE_ENV !== 'production' ? 'dev' : 'app'
                    }.therms.io/login?email=${user?.userAccount?.email}&password=${
                      user?.userAccount?.password
                    }&userId=${user?.id}`,
                    '_blank',
                  )
                }}
              >
                <FAIcon name="user" /> Impersonate
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

OrganizationUsersList.propTypes = {
  users: PropTypes.array.isRequired,
}
OrganizationUsersList.defaultProps = {}

export default OrganizationUsersList
