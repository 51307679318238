import React, { useCallback } from 'react'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import useSWR from 'swr/esm/use-swr'
import Loading from '@therms/web-common/components/Loading'
import OrganizationListItem from '../components/OrganizationListItem'
import adminApi from '../../../services/adminApi'
import OrganizationUsersList from '../../Users/shared/OrganizationUsersList'
import Survey from '../../Registrations/shared/Survey'
import { OrganizationComments } from '../components/OrganizationComments'
import OrganizationReferrals from '../components/OrganizationReferrals'
// import PropTypes from 'prop-types'

const Organization = () => {
  const { id, tab } = useParams()

  const { data, revalidate } = useSWR(`/organizations/organization/${id}`)
  const { data: orgMetadata } = useSWR(`/organizations/organization-metadata/${id}`)
  // todo: show trial end date if in trial period
  // const { data: organizationSubscription } = useSWR(`/organizations/organization-subscription?organizationId=${id}`)
  const { data: organizationUsers } = useSWR(
    `/organizations/organization-users/?organizationId=${id}`,
  )

  const { data: registrationSurvey } = useSWR(
    `/organizations/registration-survey/?organizationId=${id}`,
  )
  
  const handleChangeStatus = useCallback(
    async active => {
      await adminApi.post(`/organizations/change-organization-status?id=${id}&active=${active}`)
      revalidate()
    },
    [id],
  )

  if (!data) return <Loading />

  return (
    <div>
      <OrganizationListItem organization={data} />

      <div className='my-3'>
        {data.active ? (
          <button className='btn btn-danger' onClick={() => handleChangeStatus(false)}>
            De-Activate Organization
          </button>
        ) : (
          <button className='btn btn-outline-success' onClick={() => handleChangeStatus(true)}>
            Activate Organization
          </button>
        )}
      </div>

      <ul className='nav nav-tabs'>
        <li className='nav-item'>
          <NavLink
            activeClassName='active'
            className='nav-link'
            exact
            to={`/organizations/${id}`}
          >
            Org Info
          </NavLink>
        </li>

        {registrationSurvey && (
          <li className='nav-item'>
            <NavLink
              activeClassName='active'
              className='nav-link'
              to={`/organizations/${id}/survey`}
            >
              Survey
            </NavLink>
          </li>
        )}

        <li className='nav-item'>
          <NavLink
            activeClassName='active'
            className='nav-link'
            to={`/organizations/${id}/comments`}
          >
            Comments
          </NavLink>
        </li>

        <li className='nav-item'>
          <NavLink activeClassName='active' className='nav-link' to={`/organizations/${id}/users`}>
            Users
          </NavLink>
        </li>

        <li className='nav-item'>
          <NavLink activeClassName='active' className='nav-link' to={`/organizations/${id}/referral`}>
            Referral
          </NavLink>
        </li>
      </ul>

      {!tab && (
        <div className='mt-3'>
          <h4>Organization Info</h4>
          <pre>
            {JSON.stringify(orgMetadata, null, 2)}
          </pre>
        </div>
      )}

      {tab === 'comments' && (
        <div className='mt-3'>
          <h4>Survey</h4>
          <OrganizationComments organizationId={id} />
        </div>
      )}

      {tab === 'survey' && (
        <div className='mt-3'>
          <h4>Survey</h4>
          <Survey survey={registrationSurvey} />
        </div>
      )}

      {tab === 'users' && (
        <div className='my-3'>
          <h4>Users</h4>

          <OrganizationUsersList users={organizationUsers || []} />
        </div>
      )}

      {tab === 'referral' && (
        <div className='my-3'>
          <h4>Referral</h4>
          
          <OrganizationReferrals referralCode={id} />
        </div>
      )}
    </div>
  )
}

Organization.propTypes = {}
Organization.defaultProps = {}

export default Organization
