import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Index from './screens/index'
import Single from './screens/SingleRegistration'

import { REGISTRATIONS_ROOT, REGISTRATIONS_SINGLE } from './shared/routePaths'

const RegistrationsRoutes = () => (
  <Switch>
    <Route exact path={REGISTRATIONS_ROOT} component={Index} />
    <Route exact path={REGISTRATIONS_SINGLE} component={Single} />
  </Switch>
)

export default RegistrationsRoutes
