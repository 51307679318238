import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Index from './screens/index'
import { EMAILS_ROOT } from './shared/routePaths'

const OrganizationsRoutes = () => (
  <Switch>
    <Route exact path={EMAILS_ROOT} component={Index} />
  </Switch>
)

export default OrganizationsRoutes
