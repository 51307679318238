import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'

import Card from '@therms/web-common/components/Card'
import Comment from '../components/Comment'
import DateTime from '@therms/web-common/components/DateTime'
import FailedToLoadResource from '../../../components/FailedToLoadResource'
import Loading from '@therms/web-common/components/Loading'
import Textarea from '@therms/web-common/components/Inputs/Textarea'

import {
  REGISTRATIONS_ROOT,
  REGISTRATIONS_API_CREATE,
  REGISTRATIONS_API_COMMENT_CREATE,
  makeDeleteRegistrationRoute,
  makeSingleRegistrationRequest,
} from '../shared/routePaths'

import adminApi from 'services/adminApi'
import ErrorBoundary from '../../../components/ErrorBoundary'
import useReferralOrganizationName from '../../Organizations/shared/hooks/useReferralOrganizationName'
import { makeSingleOrganizationPath } from '../../Organizations/shared/routePaths'

const SingleRegistration = () => {
  const [updatingAccount, setUpdatingAccount] = useState(false)
  const [accountCreated, setAccountCreated] = useState(false)
  const [registrationDeleted, setRegistrationDeleted] = useState(false)
  const [submittingComment, setSubmittingComment] = useState(false)
  const { registrationId } = useParams()
  
  const { data: pendingRegistration, error } = useSWR(makeSingleRegistrationRequest(registrationId))
  const referralName = useReferralOrganizationName(pendingRegistration?.referralCode)

  if (error) return <FailedToLoadResource error={error} />
  if (!pendingRegistration || updatingAccount) return <Loading />

  const { _id, createdAt, comments, organization, referralCode, survey, user } = pendingRegistration
  

  const handleCreateAccountClick = async () => {
    try {
      setUpdatingAccount(true)

      await adminApi.post(REGISTRATIONS_API_CREATE, {
        ...pendingRegistration,
        organizationRegistrationId: _id,
      })

      setAccountCreated(true)
    } catch (err) {
      alert('Account creation failed, you can check the console for details')

      console.log(err.response)
    } finally {
      setUpdatingAccount(false)
    }
  }

  const handleDeleteRegistrationClick = async () => {
    try {
      setUpdatingAccount(true)

      await adminApi.delete(makeDeleteRegistrationRoute(_id))

      setRegistrationDeleted(true)
    } catch (err) {
      alert('Registration deletion failed, you can check the console for details')

      console.log(err.response)
    } finally {
      setUpdatingAccount(false)
    }
  }

  const handleCommentSubmit = async event => {
    event.preventDefault()
    setSubmittingComment(true)

    try {
      const form = event.target
      const newCommentPayload = {
        body: document.getElementById('new-comment').value,
        organizationRegistrationId: _id,
      }

      await adminApi.post(REGISTRATIONS_API_COMMENT_CREATE, newCommentPayload)

      mutate(makeSingleRegistrationRequest(registrationId))

      form.reset()
    } catch (err) {
      alert('Comment posting failed, you can check the console for details')

      console.log(err.response)
    } finally {
      setSubmittingComment(false)
    }
  }

  if (accountCreated || registrationDeleted)
    return (
      <div className="d-flex flex-col items-center justify-content-center">
        <h2>
          {accountCreated ? 'Account Successfully Created' : 'Registration Successfully Deleted'}
        </h2>
        <Link to={REGISTRATIONS_ROOT}>
          <button className="btn btn-lg btn-primary">Go Back</button>
        </Link>
      </div>
    )

  return (
    <div className="container">
      <div className="col text-center mb-4 font-weight-bold">
        <small className="text-muted">Pending Registration</small>

        <h1 className="mb-0">{organization.name}</h1>

        <DateTime relativeTime timestamp={createdAt} withTooltip={false} />

        <small className="text-muted ml-2">
          <DateTime timestamp={createdAt} />
        </small>

        {!!referralCode && (
          <div className="mt-2">
            <small className="d-block text-muted">Referred By</small>
            <Link to={() => makeSingleOrganizationPath(referralCode)}>{referralName}</Link>
          </div>
        )}
      </div>

      <ul className="list-group mb-4">
        <li className="font-weight-bold list-group-item list-group-item-dark text-center">
          Organization
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">City:</strong> {organization.city}
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">State:</strong> {organization.state}
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Phone:</strong>{' '}
          <a href={`tel:${organization.phone}`} rel="noopener noreferrer">
            {organization.phone}
          </a>
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Website:</strong>{' '}
          <a href={organization.website} rel="noopener noreferrer" target="_blank">
            {organization.website}
          </a>
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Timezone:</strong> {organization.timezone}
        </li>

        <li className="font-weight-bold list-group-item list-group-item-dark text-center">User</li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Name:</strong> {user.firstName} {user.lastName}
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Title:</strong> {user.title}
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Phone:</strong>{' '}
          <a href={`tel:${user.phone}`} rel="noopener noreferrer">
            {user.phone}
          </a>
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Phone:</strong>{' '}
          <a href={`mailto:${user.email}`} rel="noopener noreferrer" target="_blank">
            {user.email}
          </a>
        </li>

        <li className="font-weight-bold list-group-item list-group-item-dark text-center">
          Survey
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Referrer:</strong> {survey?.referrer || 'N/A'}
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Current Software:</strong>{' '}
          {survey?.currentSoftware || 'N/A'}
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Software Concerns:</strong>{' '}
          {survey?.currentSoftwareConcerns || 'N/A'}
        </li>

        <li className="list-group-item">
          <strong className="font-weight-bold">Goal:</strong> {survey?.softwareGoal || 'N/A'}
        </li>
      </ul>

      <div className="d-flex justify-center">
        <button className="btn btn-primary btn-lg mx-2" onClick={handleCreateAccountClick}>
          Create Account
        </button>
        <button className="btn btn-danger btn-lg mx-2" onClick={handleDeleteRegistrationClick}>
          Delete Registration Request
        </button>
      </div>

      <hr className="border-top my-4" />

      <div className="col">
        <h2 className="font-weight-bold mb-4 text-center text-2xl">Admin Comments</h2>

        {comments.map(comment => (
          <div className="mb-2" key={comment.createdAt}>
            <Comment commentData={comment} />
          </div>
        ))}

        {submittingComment && (
          <Card className="mb-2">
            <Loading />
          </Card>
        )}

        <ErrorBoundary
          FallbackComponent={
            <div className="text-danger">Error rendering the Textarea component</div>
          }
        >
          <form onSubmit={handleCommentSubmit}>
            <Textarea
              autoResize
              name="body"
              placeholder="New Comment"
              subText="Write A Comment"
              id="new-comment"
            />
            <input type="submit" value="Send" className="btn btn-primary float-right" />
          </form>
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default SingleRegistration
