import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import ActiveUsersPaidPlanAmount from './ActiveUsersPaidPlanAmount'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import moment from 'moment'
import AddressBlock from '@therms/web-common/components/Address/AddressBlock'
import DateTime from '@therms/web-common/components/DateTime'
import { Link, useRouteMatch } from 'react-router-dom'
import { makeSingleOrganizationPath } from '../shared/routePaths'
import useReferralOrganizationName from '../shared/hooks/useReferralOrganizationName'
import adminApi from '../../../services/adminApi'
import Loading from '@therms/web-common/components/Loading'

const OrganizationListItem = ({ organization }) => {
  const { params } = useRouteMatch()
  const referralName = useReferralOrganizationName(organization.referralCode)

  const [loadingUpdateSubscription, setLoadingUpdateSubscription] = useState(false)

  const borderClass = useMemo(() => {
    let className = 'border border-'
    const status = organization.subscriptionStatus

    switch (status) {
      case 'active':
        className += 'success'
        break
      case 'cancelled':
        className += 'secondary'
        break
      case 'delinquent':
        className += 'warning'
        break
      case 'trial-active':
        className += 'info'
        break
      case 'trial-ended':
        className += 'secondary'
        break
      default:
        break
    }

    return className
  }, [organization])

  const trialText = useMemo(() => {
    if (organization && organization.trialEndDate > new Date().toISOString()) {
      return (
        <span className="font-italic font-weight-bold text-muted">
          (Trial Ends <DateTime relativeTime timestamp={organization.trialEndDate} />)
        </span>
      )
    }

    return null
  }, [organization])

  const handleUpdateSubscription = async () => {
    setLoadingUpdateSubscription(true)
    try {
      await adminApi.post('/subscription/update-subscription-to-latest', {
        organizationId: organization.id,
      })
    } catch (e) {
      alert(
        'There was a problem on the server when trying to update the Organization Stripe subscription to the latest.',
      )
      console.warn('error updating subscription:', e)
    } finally {
      setLoadingUpdateSubscription(false)
    }
  }

  const stripeLink = useMemo(() => {
    if (organization.stripeCustomerId) {
      return (
        <div>
          <a
            href={`https://dashboard.stripe.com/customers/${organization.stripeCustomerId}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Stripe Customer
          </a>{' '}
          {organization.monthlyUsersSubscriptionId ? (
            <>
              |{' '}
              <a
                href={`https://dashboard.stripe.com/subscriptions/${organization.monthlyUsersSubscriptionId}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Subscription
              </a>
              {!organization.subscriptionPlanIsLatest && (
                <button
                  className="btn btn-warning btn-short btn-sm"
                  onClick={handleUpdateSubscription}
                  type="button"
                >
                  Update Subscription
                </button>
              )}
            </>
          ) : (
            <span className="text-muted">
              <small>
                <em>No Subscription</em>
              </small>
            </span>
          )}
        </div>
      )
    }

    return null
  }, [organization])

  if (!organization) return null

  return (
    <div className={`${borderClass} card my-1 p-2`} key={organization.id}>
      {loadingUpdateSubscription && <Loading centered />}

      <div className="d-flex flex-row justify-content-between">
        <div className="w-25">
          <div className="font-weight-bold">{organization.name}</div>

          {Object.hasOwnProperty.call(organization, 'subscriptionBillingInfoSetup') && (
            <div>
              $ <ActiveUsersPaidPlanAmount userCount={organization.activeUsers} />{' '}
              {!organization.subscriptionBillingInfoSetup && (
                <>
                  (
                  <span className="font-small font-weight-bolder ml-2 text-warning">
                    No Billing Info Setup
                  </span>
                  )
                </>
              )}
            </div>
          )}
          {stripeLink}

          <div className="mt-2">
            {Boolean(organization.recentOnlineUsers) && (
              <div>
                <span className="text-muted">
                  Online Users last 7 days{' '}
                  <strong className="text-light">{organization.recentOnlineUsers}</strong>
                </span>
              </div>
            )}

            {Boolean(organization.activeUsers) && (
              <div>
                <FAIcon className="text-muted" name="users" /> {organization.activeUsers}
              </div>
            )}

            {Boolean(organization.activeLocations) && (
              <div>
                <FAIcon className="text-muted" name="building" /> {organization.activeLocations}
              </div>
            )}
          </div>
        </div>

        <div className="flex-column w-25">
          <div>
            <div className="text-muted">
              <span className="font-weight-lighter">
                Created {moment(organization.createdAt).format('MMMM Do YYYY')}{' '}
              </span>{' '}
              {trialText}
            </div>

            <div>
              <a href={`tel:${organization.phone}`}>{organization.phone}</a>
            </div>

            <div>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={
                  /^http/i.test(organization.website)
                    ? organization.website
                    : `http://${organization.website}`
                }
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                {organization.website}
              </a>
            </div>
          </div>
        </div>

        <div className="flex-column w-25">
          <div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <AddressBlock {...organization.address} />
          </div>

          {!!referralName && (
            <div className="mt-1">
              <div className="text-muted font-weight-light">Referred By</div>
              <Link to={() => makeSingleOrganizationPath(organization.referralCode)}>
                {referralName}
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center">
        {organization.subscriptionStatus === 'active' && (
          <div className="text-center text-success">Active Subscription</div>
        )}

        {organization.subscriptionStatus === 'delinquent' && (
          <div className="text-center text-danger">
            <DateTime relativeTime timestamp={organization.subscriptionStatusDate} /> | Delinquent
            Subscription
          </div>
        )}

        {organization.subscriptionStatus === 'trial-active' && (
          <div className="text-center text-success">
            <DateTime relativeTime timestamp={organization.subscriptionStatusDate} /> | Trial Period
          </div>
        )}

        {organization.subscriptionStatus === 'trial-ended' && (
          <div className="text-center text-secondary">
            <DateTime relativeTime timestamp={organization.subscriptionStatusDate} /> | Trial Ended
          </div>
        )}

        {(!params.id || params.id !== organization.id) && (
          <>
            <span className="mx-2 text-muted">|</span>
            <Link to={() => makeSingleOrganizationPath(organization.id)}>Details</Link>
          </>
        )}
      </div>
    </div>
  )
}

OrganizationListItem.propTypes = {
  organization: PropTypes.object.isRequired,
}
OrganizationListItem.defaultProps = {}

export default OrganizationListItem
