import React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { makeSingleOrganizationPath } from '../shared/routePaths'

const OrganizationReferralRegistrationListItem = ({ registrationRecord }) => {
  const { data: registrationOrganizationData } = useSWR(
    `/organizations/organization/${registrationRecord?.organizationId}`,
  )

  if (!registrationRecord) return null

  const {
    active,
    createdDate,
    firstInvoiceAmount,
    hasPaidFirstInvoice,
    referralCreditPaid,
    referrerURL,
  } = registrationRecord

  return (
    <div className="border-t border-dark p-2">
      <div className="d-flex justify-content-between">
        {active ? (
          <div className="text-success">Active</div>
        ) : (
          <div className="text-secondary">Inactive</div>
        )}
        <span className="text-muted">Registered {moment(createdDate).format('MMMM Do YYYY')}</span>
      </div>

      <Link
        to={() => makeSingleOrganizationPath(registrationRecord.organizationId)}
        className="font-lg"
      >
        {registrationOrganizationData?.name}
      </Link>

      <div>
        <span className="text-muted">Credit Amount </span>
        <span className="mr-2">${firstInvoiceAmount || 0}</span>

        {hasPaidFirstInvoice ? (
          <span className="text-success mr-2">Invoice Paid</span>
        ) : (
          <span className="text-secondary mr-2">Payment Pending</span>
        )}

        {hasPaidFirstInvoice &&
          (referralCreditPaid ? (
            <span className="mr-2">Credited to referrer</span>
          ) : (
            <span className="text-warning mr-2">Credit Pending</span>
          ))}
      </div>
      <div>
        <span className="text-muted">Referrer URL</span> <span>{referrerURL}</span>
      </div>
    </div>
  )
}

OrganizationReferralRegistrationListItem.propTypes = {
  registrationRecord: PropTypes.shape({
    active: PropTypes.bool,
    clicksByReferrerURL: PropTypes.object,
    createdDate: PropTypes.string,
    firstInvoiceAmount: PropTypes.number,
    hasPaidFirstInvoice: PropTypes.bool,
    organizationId: PropTypes.string.isRequired,
    referralCreditPaid: PropTypes.bool,
    referrerURL: PropTypes.string,
  }).isRequired,
}

export default OrganizationReferralRegistrationListItem
