import React from 'react'
import { SWRConfig } from 'swr'
import adminApi from './adminApi'

// eslint-disable-next-line react/prop-types
export const SwrAppWrapper = ({ children }) => (
  <SWRConfig
    value={{
      fetcher: (...args) =>
        adminApi(...args).then(res => {
          if (res.status >= 400) throw new Error(res.statusText)

          return res.data
        }),
      focusThrottleInterval: 60000,
      refreshInterval: 120000,
    }}
  >
    {children}
  </SWRConfig>
)
