import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import thermsLogo from '@therms/web-common/images/logo_icon_black_153x154.png'
import MailsNavbarLink from '../../Emails/shared/LayoutNavbarLink'
import RegistrationNavbarLink from '../../Registrations/shared/LayoutNavbarLink'
import OrganizationsNavbarLink from '../../Organizations/shared/LayoutNavbarLink'

import './LayoutWrapper.scss'

const LayoutWrapper = ({ children }) => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <NavLink className="navbar-brand" to="/">
          <div className="d-flex text-muted">
            <img src={thermsLogo} width={40} alt="therms" />

            <span className="ml-2">THERMS Admin</span>
          </div>
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav navbar-nav menu">
            <RegistrationNavbarLink />

            <OrganizationsNavbarLink />

            <MailsNavbarLink />
          </ul>
        </div>
      </nav>

      <div className="m-1 m-lg-3">{children}</div>
    </div>
  )
}

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
LayoutWrapper.defaultProps = {}

export default LayoutWrapper
