// babel polyfills
import 'core-js'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production')
  Sentry.init({dsn: "https://baf328817aa543e09e6450b0fcf43e38@sentry.io/2906692"})

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept()
}
