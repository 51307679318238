import React from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

import Card from '@therms/web-common/components/Card'
import FailedToLoadResource from '../../../components/FailedToLoadResource'
import Loading from '@therms/web-common/components/Loading'

import { makeSingleRegistrationRoute, REGISTRATIONS_API_ROOT } from './routePaths'

const RegistrationsDashboardItem = () => {
  const { data: pendingRegistrations, error } = useSWR(REGISTRATIONS_API_ROOT)

  if (error) return <FailedToLoadResource error={error} />
  if (!pendingRegistrations)
    return (
      <Card header="Pending Registrations">
        <Loading />
      </Card>
    )

  return (
    <Card heading={`${pendingRegistrations.length} Pending Registrations`}>
      <div className="list-group list-group-flush">
        {pendingRegistrations.map(registration => (
          <Link
            to={makeSingleRegistrationRoute(registration.id)}
            className="list-group-item"
            key={registration.id}
          >
            {registration.organization.name}
          </Link>
        ))}
      </div>
    </Card>
  )
}

export default RegistrationsDashboardItem
