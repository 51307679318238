import React from 'react'
import NavBarLink from '../../Layout/shared/NavBarLink'

import {ORGANIZATIONS_ROOT, TRIAL_ORGANIZATIONS} from './routePaths'

const OrganizationsNavbarLink = () => (
  <><NavBarLink path={TRIAL_ORGANIZATIONS} title="Trial" />
    <NavBarLink exact path={ORGANIZATIONS_ROOT} title="Organizations" />
  </>
)

export default OrganizationsNavbarLink
