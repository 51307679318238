import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@therms/web-common/components/Card'
import Loading from '@therms/web-common/components/Loading'
import Textarea from '@therms/web-common/components/Inputs/Textarea'
import Comment from '../../Registrations/components/Comment'
import {
  makeSingleRegistrationRequest,
  REGISTRATIONS_API_COMMENT_CREATE,
} from '../../Registrations/shared/routePaths'
import FailedToLoadResource from '../../../components/FailedToLoadResource'
import useSWR, { mutate } from 'swr'
import adminApi from '../../../services/adminApi'

const OrganizationComments = ({ organizationId }) => {
  const [submittingComment, setSubmittingComment] = useState(false)

  const { data: organizationRegistration, error, revalidate } = useSWR(
    organizationId
      ? `/organizations/get-organization-registration-by-organization-id?organizationId=${organizationId}`
      : undefined,
  )

  if (error) return <FailedToLoadResource error={error} />

  const { _id, comments } = organizationRegistration || {}

  const handleCommentSubmit = async event => {
    event.preventDefault()
    setSubmittingComment(true)

    try {
      const form = event.target
      const newCommentPayload = {
        body: document.getElementById('new-comment').value,
        organizationRegistrationId: _id,
      }

      await adminApi.post(REGISTRATIONS_API_COMMENT_CREATE, newCommentPayload)

      mutate(makeSingleRegistrationRequest(_id))

      form.reset()
    } catch (err) {
      alert('Comment posting failed, you can check the console for details')

      console.log(err.response)
    } finally {
      setSubmittingComment(false)
      revalidate()
    }
  }

  return (
    <div className="col">
      <h2 className="font-weight-bold mb-4 text-center text-2xl">Admin Comments</h2>

      <form onSubmit={handleCommentSubmit}>
        <div className="d-flex w-100">
          <div className="w-100">
            <Textarea
              autoResize={false}
              name="body"
              placeholder="New Comment"
              subText="Write A Comment"
              id="new-comment"
            />
          </div>
          <input type="submit" value="Send" className="btn btn-primary ml-1" />
        </div>
      </form>

      {submittingComment && (
        <Card className="mb-2">
          <Loading />
        </Card>
      )}

      {comments?.reverse()?.map(comment => (
        <div className="mb-2" key={comment.createdAt}>
          <Comment commentData={comment} />
        </div>
      ))}
    </div>
  )
}

OrganizationComments.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export { OrganizationComments }
