import React from 'react'

import RegistrationsDashboardItem from 'modules/Registrations/shared/RegistrationsDashboardItem'
import OrganizationsCard from '../../Organizations/shared/modules/Dashboard/OrganizationsCard'
import UsersCard from '../../Users/shared/modules/Dashboard/UsersCard'
import ReportsCard from '../../Reports/shared/modules/Dashboard/ReportsCard'

const Dashboard = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <RegistrationsDashboardItem />
        </div>

        <div className="col-12 col-lg-4">
          <OrganizationsCard />
        </div>

        <div className="col-12 col-lg-4">
          <UsersCard />
        </div>

        <div className="col-12 col-lg-4">
          <ReportsCard />
        </div>
      </div>
    </div>
  )
}

Dashboard.propTypes = {}
Dashboard.defaultProps = {}

export default Dashboard
