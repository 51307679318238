import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import moment from 'moment'

export default class ErrorBoundary extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    autoRefresh: PropTypes.bool,
    children: PropTypes.any.isRequired,
    FallbackComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    autoRefresh: false,
    FallbackComponent: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      error: null,
      errorInfo: null,
      eventId: '',
      // eventId: '',
      refreshDate: undefined,
    }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
      refreshDate: moment().add(30, 'seconds'),
    })

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })

      const eventId = Sentry.captureException(error)

      this.setState({
        eventId,
      })
    })
  }

  render() {
    if (this.state.errorInfo) {
      const Fallback = this.props.FallbackComponent

      if (!Fallback) return null // can be a hidden error catch

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Fallback {...this.state} />
    }

    // Normally, just render children
    return this.props.children
  }
}
