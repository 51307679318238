import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Index from './screens/index'
import Organization from './screens/Organization'
import TrialOrganizations from './screens/TrialOrganizations'

import { ORGANIZATIONS_ROOT, TRIAL_ORGANIZATIONS, VIEW_ORGANIZATION } from './shared/routePaths'

const OrganizationsRoutes = () => (
  <Switch>
    <Route exact path={ORGANIZATIONS_ROOT} component={Index} />
    <Route exact path={TRIAL_ORGANIZATIONS} component={TrialOrganizations} />
    <Route path={VIEW_ORGANIZATION} component={Organization} />
  </Switch>
)

export default OrganizationsRoutes
