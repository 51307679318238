import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import reducer from './reducer'

const composeEnhancers =
  process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

const middleware = [thunk]

const enhancer = composeEnhancers(applyMiddleware(...middleware))

const initialState = {}

const store = createStore(reducer, initialState, enhancer)

export default store
