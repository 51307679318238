import React from 'react'
import PropTypes from 'prop-types'

import Card from '@therms/web-common/components/Card'
import DateTime from '@therms/web-common/components/DateTime'

const Comment = ({ commentData }) => {
  const { body, createdAt, createdBy } = commentData

  return (
    <Card>
      <small className="text-muted">
        <span className="font-weight-bold mr-2">{createdBy}</span>
        <DateTime timestamp={createdAt} relativeTime />
      </small>
      <p className="mb-0 with-whitespace">{body}</p>
    </Card>
  )
}

Comment.propTypes = {
  commentData: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    body: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
  }),
}

Comment.defaultProps = {
  commentData: PropTypes.shape({
    body: '',
  }),
}

export default Comment
