export default function(userCount) {
  switch (true) {
    case userCount < 6:
      return 37.50
    case userCount < 16:
      return 90
    case userCount < 31:
      return 135
    case userCount < 51:
      return 187.5
    case userCount < 76:
      return 262.5
    case userCount < 101:
      return 337.5
    case userCount < 151:
      return 450
    case userCount < 201:
      return 600
    case userCount < 301:
      return 750
    case userCount < 401:
      return 975
    case userCount > 400:
      return userCount * 3
    default:
      return 0
  }
}
