import React, { useMemo, useState } from 'react'
import useSWR from 'swr'
import Loading from '@therms/web-common/components/Loading'
import FailedToLoadResource from '../../../components/FailedToLoadResource'
import useLocalStorage from '../../../hooks/useLocalStorage'
import qs from 'qs'
import { sortBy } from 'lodash-es'
import calculatePlanPrice from '../utils/calculatePlanPrice'
import OrganizationListItem from '../components/OrganizationListItem'
import TextSearch from '@therms/web-common/components/Inputs/TextSearch'
import OrganizationsSubscriptionTierChart from '../components/OrganizationsSubscriptionTierChart'

const makeLSKey = x => `Organizations_${x}`

const Organizations = () => {
  const [searchText, setSearchText] = useState()
  const [tab, setTab] = useLocalStorage(makeLSKey('tab'), 'active')

  const { data, error, isValidating } = useSWR(
    `/organizations/organizations?${qs.stringify({
      query: { where: { active: tab === 'active' } },
    })}`,
  )

  const orgsSorted = useMemo(() => {
    return sortBy(data || [], 'subscriptionStatus').filter(org => {
      if (searchText?.length > 0) {
        try {
          const rgx = new RegExp(searchText, 'gi')
          return rgx.test(JSON.stringify(org))
        } catch (e) {
          return false
        }
      }

      return org
    })
  }, [data, searchText])

  const [activeValue, activeCount] = useMemo(() => {
    window.orgs = data
    console.log('org data available: window.orgs = data')
    return (data || []).reduce(
      (acc, org) => {
        // only add to total value if the org has billing info setup
        if (org.subscriptionStatus === 'active') {
          // eslint-disable-next-line no-param-reassign
          acc[0] += calculatePlanPrice(org.activeUsers)
          acc[1]++
        }

        return acc
      },
      [0, 0],
    )
  }, [data])

  if (error) return <FailedToLoadResource error={error} />
  if (!orgsSorted) return <Loading />

  return (
    <div>
      <div className="btn-group d-flex justify-content-center">
        <button
          type="button"
          className={`btn btn-${tab === 'active' ? 'primary' : 'secondary'}`}
          onClick={() => setTab('active')}
        >
          Active
        </button>

        <button
          type="button"
          className={`btn btn-${tab === 'inactive' ? 'primary' : 'secondary'}`}
          onClick={() => setTab('inactive')}
        >
          In-Active
        </button>
      </div>

      {isValidating && (
        <div className={orgsSorted?.length ? 'position-absolute' : ''}>
          <Loading />
        </div>
      )}

      {tab === 'active' && (
        <>
          <div className="mt-3">
            <OrganizationsSubscriptionTierChart />
          </div>
          <div className="mt-3">
            <span className="text-muted">Monthly Revenue (total)</span> ${activeValue} (
            {activeCount} "active" Organizations, ${(activeValue / activeCount).toFixed(0)} avg.
            MRR)
          </div>
        </>
      )}

      <div className="mt-3">
        <TextSearch onChange={setSearchText} value={searchText} />
      </div>

      <div className="mt-3">
        {orgsSorted.map(org => (
          <OrganizationListItem key={org.id} organization={org} />
        ))}
      </div>
    </div>
  )
}

Organizations.propTypes = {}
Organizations.defaultProps = {}

export default Organizations
