import axios from 'axios'
import cookies from 'js-cookie'

const axiosConfig = {
  baseURL: process.env.ADMIN_SERVER,
  headers: { 'Cache-Control': 'no-cache' },
  withCredentials: true,
}

const adminApi = axios.create(axiosConfig)

adminApi.interceptors.request.use(
  function(config) {
    // add jwt to req header
    // eslint-disable-next-line no-param-reassign
    config.headers[process.env.JWT_HEADER_KEY] = cookies.get(process.env.AUTH_COOKIE_NAME)
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
adminApi.interceptors.response.use(
  response =>
    // Do something with response data
    // console.log('ok', response.status)
    response,
  error => {
    // eslint-disable-next-line no-restricted-globals
    if (error && error.response && error.response.status === 401) location.href = '/login'

    return Promise.reject(error)
  },
)

export default adminApi
