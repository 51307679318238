import React, { useRef, useState } from 'react'
import adminApi from '../../../services/adminApi'
import Loading from '@therms/web-common/components/Loading'
import cookie from 'js-cookie'
// import PropTypes from 'prop-types'

const makeLSKey = x => `Login_${x}`

const Login = () => {
  const emailPersist = window.localStorage.getItem(makeLSKey('Login_email'))
  const passwordPersist = window.localStorage.getItem(makeLSKey('Login_password'))

  const [email, setEmail] = useState(emailPersist)
  const [loading, setLoading] = useState()
  const [password, setPassword] = useState(passwordPersist)
  const [error, setError] = useState()

  const rememberRef = useRef()

  function clearPersist() {
    window.localStorage.removeItem(makeLSKey('Login_password'))
    window.localStorage.removeItem(makeLSKey('Login_email'))
    window.location.reload()
  }

  async function handleLogin() {
    setLoading(true)
    setError('')
    try {
      const remember = rememberRef?.current?.checked

      const { data } = await adminApi.post('/authenticate/login', { email, password })

      if (!data?.message) throw new Error('no token provided')

      cookie.set(process.env.AUTH_COOKIE_NAME, data?.message, { expires: 1 })

      if (remember) {
        window.localStorage.setItem(makeLSKey('Login_email'), email)
        window.localStorage.setItem(makeLSKey('Login_password'), password)
      }

      // eslint-disable-next-line no-restricted-globals
      location.href = '/'
    } catch (e) {
      console.log('/authenticate/login error response', e.response)
      setLoading(false)
      setError(e.response.data?.message ?? e.response.data)
    }
  }

  if (loading) return <Loading />

  return (
    <div className="align-content-center d-flex justify-content-center h-100 w-100">
      <div className="min-width-300">
        <div className="form-group">
          {emailPersist ? (
            <span>{emailPersist}</span>
          ) : (
            <input
              className="form-control"
              onChange={({ target: { value } }) => setEmail(value)}
              placeholder="email"
              type="text"
              value={email}
            />
          )}
        </div>

        <div className="form-group">
          {passwordPersist ? (
            <span>*********</span>
          ) : (
            <input
              className="form-control"
              onChange={({ target: { value } }) => setPassword(value)}
              placeholder="password"
              type="password"
              value={password}
            />
          )}
        </div>

        <div>
          {emailPersist || passwordPersist ? (
            <button className="btn btn-link" onClick={clearPersist} type="button">
              Clear Saved Credentials
            </button>
          ) : (
            <>
              <input ref={rememberRef} value type="checkbox" />
              <span> Remember this damn browser</span>
            </>
          )}
        </div>

        <button className="btn btn-primary" onClick={handleLogin}>
          Login
        </button>

        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </div>
    </div>
  )
}

Login.propTypes = {}
Login.defaultProps = {}

export default Login
