import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import AuthWrapper from './modules/Auth/shared/AuthWrapper'
import LayoutWrapper from 'modules/Layout/shared/LayoutWrapper'
import Login from './modules/Auth/screens/Login'
import Routes from './modules/Routes'
import store from './state'
import { SwrAppWrapper } from './services/swr'

import 'styles/index.scss'

export default () => (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route component={Login} path="/login" />

        <Route
          render={() => (
            <AuthWrapper>
              <SwrAppWrapper>
                <LayoutWrapper>
                  <Routes />
                </LayoutWrapper>
              </SwrAppWrapper>
            </AuthWrapper>
          )}
        />
      </Switch>
    </BrowserRouter>
  </Provider>
)
