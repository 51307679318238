import React from 'react'
import PropTypes from 'prop-types'

import Radio from '@therms/web-common/components/Inputs/Radio'

const SelectAccessLevel = ({ setSelectedAccess }) => {
  const accessLevels = [
    { label: 'Regular', value: 1 },
    { label: 'Supervisor', value: 2 },
    { label: 'Manager', value: 3 },
  ]

  return (
    <div className="d-flex flex-wrap">
      {accessLevels.map(({ label, value }) => (
        <div className="mr-2" key={value}>
          <Radio
            label={label}
            name="access-level"
            onChange={inputValue => setSelectedAccess(inputValue)}
            key={value}
            value={value}
          />
        </div>
      ))}
    </div>
  )
}

SelectAccessLevel.propTypes = {
  setSelectedAccess: PropTypes.func.isRequired,
}

export default SelectAccessLevel
