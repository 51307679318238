import React, { useMemo } from 'react'
import Card from '@therms/web-common/components/Card'
import useSWR from 'swr'
import moment from 'moment'

const UsersCard = () => {
  const startOfDay = useMemo(
    () =>
      moment()
        .startOf('day')
        .toISOString(),
    [],
  )

  const { data: active } = useSWR('/users/active')
  const { data: usersOnlineToday } = useSWR(`/users/online-since?fromDate=${startOfDay}`)
  const { data: usersOnlineNow } = useSWR(`/users/online`)

  return (
    <Card heading="Users">
      <div className="flex-row-jcb">
        Active Users
        <div>
          <span className="badge badge-success">{active || 0}</span>
        </div>
      </div>

      <div className="flex-row-jcb">
        Online Now
        <div>
          <span className="badge badge-info">{usersOnlineNow || 0}</span>
        </div>
      </div>

      <div className="flex-row-jcb">
        Online Today
        <div>
          <span className="badge badge-secondary">{usersOnlineToday || 0}</span>
        </div>
      </div>
    </Card>
  )
}

UsersCard.propTypes = {}
UsersCard.defaultProps = {}

export default UsersCard
