import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Dashboard from './screens'

const DashboardRoutes = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
  </Switch>
)

export default DashboardRoutes
