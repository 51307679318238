import React from 'react'
import PropTypes from 'prop-types'

const Survey = ({ survey }) => {
  return (
    <div>
      <div>
        <strong className="font-weight-bold">Referrer:</strong> {survey?.referrer || 'N/A'}
      </div>

      <div>
        <strong className="font-weight-bold">Current Software:</strong>{' '}
        {survey?.currentSoftware || 'N/A'}
      </div>

      <div>
        <strong className="font-weight-bold">Software Concerns:</strong>{' '}
        {survey?.currentSoftwareConcerns || 'N/A'}
      </div>

      <div>
        <strong className="font-weight-bold">Goal:</strong> {survey?.softwareGoal || 'N/A'}
      </div>
    </div>
  )
}

Survey.propTypes = {
  survey: PropTypes.object.isRequired,
}
Survey.defaultProps = {}

export default Survey
